export const set = {
	taskEnable: undefined,
	releaseEnable: undefined,
	releaseFreeTime: undefined,
	releaseDeductType: undefined,
	releaseTimeoutInterval: undefined,
	releaseTimeoutValue: undefined,
	releaseTimeoutMax: undefined,
	releaseReturnType: undefined,
	ordersEnable: undefined,
	ordersFreeTime: undefined,
	ordersDeductType: undefined,
	ordersTimeoutInterval: undefined,
	ordersTimeoutValue: undefined,
	ordersTimeoutMax: undefined,
	ordersReturnType: undefined,
	serviceConfirmTime: undefined,
}