<template>
  <div>
    <div class="cardTitle">任务设置</div>
    <a-form-model
      :model="set"
      :label-col="labelCol"
      title="基本设置"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="任务发布">
        <a-radio-group v-model="set.taskEnable" button-style="solid">
          <a-radio-button :value="true"> 开启 </a-radio-button>
          <a-radio-button :value="false"> 关闭 </a-radio-button>
        </a-radio-group>
        <div>设置关闭后，用户将无法在APP上发布任务</div>
      </a-form-model-item>
      <a-form-model-item label="取消订单设置——发布者">
        <a-space>
          <a-checkbox v-model="set.releaseEnable" />
          <span :class="[set.releaseEnable===false?'disabled':'']"
            >被接单后，发布者在<a-input :disabled='set.releaseEnable===false' v-model="set.releaseFreeTime"></a-input
            >分钟内可以免费取消订单，超过时间时支付违约金</span
          >
        </a-space>
      </a-form-model-item>
      <a-form-model-item label="发布者违约金计算方式">
        <a-radio-group v-model="set.releaseDeductType" :disabled='set.releaseEnable===false' button-style="solid">
          <a-radio-button :value="1"> 百分比扣除 </a-radio-button>
          <a-radio-button :value="2"> 固定金额 </a-radio-button>
        </a-radio-group>
        <!-- 发布者-百分比扣除 -->
        <div v-if="set.releaseDeductType === 1" :class="[set.releaseEnable===false?'disabled':'']">
          在免费取消时限后取消订单，每过<a-input :disabled='set.releaseEnable===false'
            v-model="set.releaseTimeoutInterval"
          ></a-input
          >分钟，扣除报酬<a-input v-model="set.releaseTimeoutValue" :disabled='set.releaseEnable===false'></a-input>%
          的违约金/积分，单次任务扣除上限为<a-input :disabled='set.releaseEnable===false'
            v-model="set.releaseTimeoutMax"
          ></a-input
          >%
          <div>计时方式为0 &lt;X ≤设定时间，X为取消订单时间</div>
          <div>
            例：设定时间为5分钟，则00:01至05:00期间可免费取消，05:01至10:00期间扣除
          </div>
        </div>
        <div v-else-if="set.releaseDeductType === 2" :class="[set.releaseEnable===false?'disabled':'']">
          每过<a-input v-model="set.releaseTimeoutInterval" :disabled='set.releaseEnable===false'></a-input>分钟，扣除
          <a-input v-model="set.releaseTimeoutValue" :disabled='set.releaseEnable===false'></a-input>
          个单位的违约金/积分，单次任务扣除上限为<a-input :disabled='set.releaseEnable===false'
            v-model="set.releaseTimeoutMax"
          ></a-input
          >个单位
          <div>计时方式为0 &lt;X ≤设定时间，X为取消订单时间</div>
          <div>
            例：设定时间为5分钟，则00:01至05:00期间可免费取消，05:01至10:00期间扣除
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="扣除发布者违约金后">
        <a-radio-group v-model="set.releaseReturnType" :disabled='set.releaseEnable===false' button-style="solid">
          <a-radio-button :value="1"> 返还至帮手 </a-radio-button>
          <a-radio-button :value="2"> 不做返还 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <!-- 帮手 -->
      <a-form-model-item label="取消订单设置——帮手">
        <a-space>
          <a-checkbox v-model="set.ordersEnable" />
          <span :class="[set.ordersEnable===false?'disabled':'']"
            >被接单后，帮手在<a-input :disabled='set.ordersEnable===false' v-model="set.ordersFreeTime"></a-input
            >分钟内可以免费取消订单，超过时间时支付违约金</span
          >
        </a-space>
      </a-form-model-item>
      <a-form-model-item label="帮手违约金计算方式">
        <a-radio-group v-model="set.ordersDeductType" :disabled='set.ordersEnable===false' button-style="solid">
          <a-radio-button :value="1"> 百分比扣除 </a-radio-button>
          <a-radio-button :value="2"> 固定金额 </a-radio-button>
        </a-radio-group>
        <!-- 帮手-百分比扣除 -->
        <div v-if="set.ordersDeductType === 1" :class="[set.ordersEnable===false?'disabled':'']">
          在免费取消时限后取消订单，每过<a-input  :disabled='set.ordersEnable===false'
            v-model="set.ordersTimeoutInterval"
          ></a-input
          >分钟，扣除报酬<a-input v-model="set.ordersTimeoutValue"  :disabled='set.ordersEnable===false'></a-input>%
          的违约金/积分，单次任务扣除上限为<a-input  :disabled='set.ordersEnable===false'
            v-model="set.ordersTimeoutMax"
          ></a-input
          >%
          <div>计时方式为0 &lt;X ≤设定时间，X为取消订单时间</div>
          <div>
            例：设定时间为5分钟，则00:01至05:00期间可免费取消，05:01至10:00期间扣除
          </div>
        </div>
        <!-- 帮手-固定 -->
        <div v-else-if="set.ordersDeductType === 2" :class="[set.ordersEnable===false?'disabled':'']">
          每过<a-input v-model="set.ordersTimeoutInterval"  :disabled='set.ordersEnable===false'></a-input>分钟，扣除
          <a-input v-model="set.ordersTimeoutValue"  :disabled='set.ordersEnable===false'></a-input>
          个单位的违约金/积分，单次任务扣除上限为<a-input  :disabled='set.ordersEnable===false'
            v-model="set.ordersTimeoutMax"
          ></a-input
          >个单位
          <div>计时方式为0 &lt;X ≤设定时间，X为取消订单时间</div>
          <div>
            例：设定时间为5分钟，则00:01至05:00期间可免费取消，05:01至10:00期间扣除
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="扣除帮手违约金后">
        <a-radio-group v-model="set.ordersReturnType" :disabled='set.ordersEnable===false' button-style="solid">
          <a-radio-button :value="1"> 返还至发布者 </a-radio-button>
          <a-radio-button :value="2"> 不做返还 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="服务后自动确认完成时间">
        待确认后<a-input v-model="set.serviceConfirmTime"></a-input
        >天，自动确认完成任务
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        <a-button style="margin-left: 10px" @click="getData"> 重置 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { set } from "./depend/config";
import { getSetting, Setting } from "@/api/operation/task";
export default {
	data() {
		return {
			set,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await getSetting();
			this.set = res.data;
		},
		async onSubmit() {
			let res = await Setting(this.set);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
	},
};
</script>

<style lang="less" scoped>
/deep/.ant-input {
  width: 73px;
  display: inline;
  margin: 0 5px 0 5px;
}
.disabled{
    color: #00000040;
}
</style>